<script lang="ts">
import { h } from 'vue';

export default {
  props: {
    tag: { type: String, default: 'div' },
    text: { type: String, default: '' },
    defSlot: { type: Function, default: () => '' },
  },
  setup(props, { slots, attrs }) {
    return () => h(props.tag, {
      ...attrs,
      innerHTML: props.text,
    }, slots);
  },
}
</script>
